.content-info{
	min-height: 125px;
	background: $brand-secondary;
}

.footer-brand{
	padding-bottom: 20px;

	@media (min-width: 768px){
		padding-bottom: 0px;
	}
}

.contact-base{
	color: $white;
	font-family: $font-primary;
	font-size: 0.65em;
	text-align: center;

	@media (min-width: 768px) 
	  	and (max-width: 1024px) {
	  	font-size: 0.75em;
	}

	@media (min-width: 1024px) 
		and (max-width: 1600px)  { 
		font-size: 1.0em;
	}

	@media (min-width: 1600px)  { 
		font-size: 1.1em;
	}		
}

.contact-landline{
	@extend .contact-base;
}

.contact-mobile{
	@extend .contact-base;
}

.contact-email{
	@extend .contact-base;		
}

.contact-email a {
	color: $white;
	text-decoration: none;
}

.contact-address{
	@extend .contact-base;		
}

.contact-base:before{
	color: $brand-quaternary;
	text-transform: uppercase;
}

.contact-email:before { content: 'E:\00a0'; }
.contact-landline:before { content: 'T:\00a0'; }
.contact-mobile:before { content: 'M:\00a0'; }