
// ########################################## Base Page Headers ########################################

.home-page-header{	
	position: relative;
	height: 425px;
	width: 100%;

    @media (min-width: 768px) 
      and (max-width: 1023px) {
        height: 700px;
    }

    @media (min-width: 1024px)  { 
        height: 1000px;
    }

  	background: 
	    /* top, transparent green, faked with gradient */ 
	    linear-gradient(
	      $opaque-background, 
	      $opaque-background
	    ),
	    url(../images/home_page_banner.png),
	    no-repeat,
	    fixed,
	    center;	
 	background-size: cover;

	//
	.home-header-strapline{
		position: absolute;
	  	top: 80%;
	  	text-align: center;	
		font-family: $font-primary;
		color: $brand-quaternary;
		font-style: italic;
		font-weight: 400;
		font-size: 1.0em;  
  		left: 50%;
  		transform: translateX(-50%);

	    @media (min-width: 667px) 
	      and (max-width: 767px) {
	      	top: 85%;
	    }	  		

	    @media (min-width: 768px) 
	      and (max-width: 1023px) {
			font-size: 1.35em;         
	    }

	    @media (min-width: 1024px)  { 
			font-size: 1.45em;        
	    }	

	    @media (min-width: 1600px)  { 
			font-size: 1.5em;     
	    }	    	
	}

	// 
	.home-page-logo{
		position: absolute;
		top: 5%;
  		left: 50%;
  		transform: translateX(-50%);

	    @media (min-width: 667px) 
	      and (max-width: 767px) {
	    }	    

	    @media (min-width: 768px) 
	      and (max-width: 1023px) {
			top: 15%;
	    }

	    @media (min-width: 1024px)  { 
	    	top: 30%;
	    }

	    @media (min-width: 1600px)  { 
	    	top: 30%;
	    }	    
	}
}

.generic-page-header{
	position: relative;
	height: 325px;

    @media (min-width: 768px) 
      and (max-width: 1023px) {
        height: 500px;
    }

    @media (min-width: 1024px)  { 
        height: 650px;
    }

	width: 100%;
  	background: 
	    no-repeat,
	    fixed,
	    center;	
 	background-size: cover;
}

.header-bottom-border{
		position: absolute;
		bottom:-20px;
		height: 200px;
		width:100%;
		background: 
		    linear-gradient(
		      transparent, 
		      transparent
		    ),
		    url(../images/triangle_pattern.png),
		    repeat,
		    fixed,
		    center;	
		 z-index: 0;
	}

.generic-header-bottom-border{
		position: absolute;
		bottom:-180px;
		height: 280px;
		width:100%;
		background: 
		    linear-gradient(
		      transparent, 
		      transparent
		    ),
		    url(../images/triangle_pattern_float.png),
		    repeat-x,
		    fixed,
		    center;	
		 z-index: 0;
	}	

.contact-header-bottom-border{
		position: absolute;
		height: 280px;
		bottom: 0px;
		width:100%;
		background: 
		    linear-gradient(
		      transparent, 
		      transparent
		    ),
		    url(../images/triangle_pattern_float.png),
		    repeat-x,
		    fixed,
		    center;	
		 z-index: 0;
	}	


.contact-page-header{		
	// empty holder to cludge the Google Map background
}

.mid-page-border{
	position: relative;
	margin-top:-20px;
	height: 120px;
	width:100%;
	background: 
	    linear-gradient(
	      transparent, 
	      transparent
	    ),
	    url(../images/triangle_pattern_float_low.png),
	    repeat-x,
	    fixed,
	    center;	
	 z-index: -999;	
}