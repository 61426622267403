a img {
    border: 0;
}

// ########################################## Home Page ########################################

.photo-gallery-thumbnail{
	padding-bottom: 25px;
}

.home-welcome-title{
	@extend .base-title;
}

.home-welcome-sub-title{
	@extend .base-sub-title;
}

.home-welcome-message{
	@extend .base-text;
}	

.home-welcome-link{
	@extend .base-link;
	text-transform: lowercase;
}	

.features-section{
	background: $brand-secondary;
    @media (min-width: 768px) {
	    outline: 1px solid $white;
	    outline-offset: -26px;    	
    }

}

.features-title{
	color: $brand-quaternary;
	font-family: $font-primary;
	font-size: 0.9em;
	font-weight: 400;
	width:90%;
	padding-left: 10%;
	text-align: center;

	@media (min-width: 768px) 
	  and (max-width: 1024px) {
	} 

	@media (min-width: 1024px) 
	  and (max-width: 1600px)  { 
      font-size: 1.35em;
	}

	@media (min-width: 1600px)  { 
      border-left:none;
      font-size: 1.5em;
	}	
}

.features-text{
	color: $white;
	font-family: $font-secondary;
	font-size: 0.8em;
	width:95%;
	padding-left: 5%;
	text-align: center;

	@media (min-width: 768px) 
	  and (max-width: 1024px) {
      width:90%;
      padding-left: 10%;
	} 

	@media (min-width: 1024px) 
	  and (max-width: 1600px)  { 
      font-size: 1.05em;
      width:90%;
      padding-left: 10%;
	}

	@media (min-width: 1600px)  { 
      border-left:none;
      font-size: 1.15em;
      width:75%;
      padding-left: 20%;
	}	
}

.features-link-text{
	color: $brand-quaternary;
	font-family: $font-primary;
	font-size: 0.8em;
	font-weight: 400;
	width:75%;
	padding-left: 20%;
	text-align: center;
	cursor: pointer;
	//padding-bottom: 25px;

	@media (min-width: 768px) 
	  and (max-width: 1024px) {
      font-size: 0.85em;
	} 

	@media (min-width: 1024px) 
	  and (max-width: 1600px)  { 
      font-size: 1.05em;
	}

	@media (min-width: 1600px)  { 
      border-left:none;
      font-size: 1.15em;
	}	
}

.features-link-text a {
	color:$brand-quaternary;
	text-decoration: none;
	text-transform: lowercase;
}

.features-link-text a:hover, a:focus {
    color: $brand-quaternary;
    text-decoration: none;
}	


// hacks for the white border around the features section...GAAARRRKKKK there must be a better way...
.right-border-container, .left-border-container {
  display: inline-block;
  position: relative;
}

.right-border-container:before {
  display: block;
  content: '';
  position: absolute;
  top: 25px;
  right: 25px;
  bottom: 25px;
  left: 25px;
  border: 1px solid white;
	//
	@media (min-width: 768px) 
	  and (max-width: 1024px) {
      border-left:none;
      left: 0px;
	} 

	@media (min-width: 1024px) 
	  and (max-width: 1600px)  { 
      border-left:none;
      left: 0px;
	}

	@media (min-width: 1600px)  { 
      border-left:none;
      left: 0px;
	}

}

.left-border-container:before {
  display: block;
  content: '';
  position: absolute;
  top: 25px;
  right: 25px;
  bottom: 25px;
  left: 25px;
  border: 1px solid white;
	//
	@media (min-width: 768px) 
	  and (max-width: 1024px) {
      	border-right:none;
		right: 0px;;
	} 

	@media (min-width: 1024px) 
	  and (max-width: 1600px)  { 
      	border-right:none;
		right: 0px;
	}

	@media (min-width: 1600px)  { 
      	border-right:none;
		right: 0px;
	}  
}

.home-gallery{
	height: 250px;
}

.memorial-gallery img {
	padding-right:30px;
}

.memorial-gallery .memorial-gallery-image-pad{
	border: 2px red;
}

// ########################### SERVICES PAGE #############################

.process-step-title{
	color: $brand-secondary;
	font-family: $font-primary;
	font-size: 1.25em;
	font-weight: 400;
}

.process-step-description{
	@extend .base-text;
	text-align: initial;
}	

// ########################### MEMORIALS PAGE #############################

.memorial-wrapper{
	background-color: $brand-tertiary;
	cursor: pointer;
    outline: 1px solid $white;
    outline-offset: -15px;	

    > a{
    	text-decoration: none;
    }
}

.memorial-image{
	padding: 30px;
}

.memorial-title{
	@extend .base-title;
	padding: 0 30px 0 10px;
}

.memorial-sub-title{
	@extend .base-sub-title;
	padding: 0 30px 0 10px;
}

.memorial-text{	
	@extend .base-text;
	padding: 0 30px 10px 30px;
}

.text-center{
	text-align: center;
}

.memorial-price{
	font-family: $font-secondary;
	color: $brand-primary;
	font-size: 2.0em;
}

// .memorial-price:before{
// 	content: '$';
// }

.memorial-included{
	@extend .memorial-text;
	text-align: center;
	padding-bottom: 50px;
	text-transform: lowercase;
}

.category-filter{
	float:right;
    position:relative;
    left:-50%;
    text-align:left;	
	
	@media (min-width: 320px) 
	  and (max-width: 568px){
	  	width: 350px;
	}

	ul {
	    list-style:none;
	    position:relative;
	    left:50%;
		margin-top:15px;

		@media (min-width: 320px) 
		  and (max-width: 568px) {
		  	width: 350px;
		}

		> a {
			color:$white;
			text-decoration: none;
		}

		> a:hover, > a:focus {
	        color: $white;
	        text-decoration: none;
		}		
	}

	li {
		float:left;
		position:relative;
		font-family: $font-secondary;
		font-size:1.15em;
 		padding: 10px 30px 10px 30px;
		display:inline-block;
    	text-align: center;
    	background: $brand-secondary;
    	margin: 5px;
		
		@media (min-width: 320px) 
		  and (max-width: 568px){
		  	padding: 0px 10px;
			font-size:0.75em;
		}    	

	}

	li:hover{
		background: $brand-quaternary;
	}	
	
}

//################################ Memorial Page Dialog ########################################

.memorial-dialog-title{
	@extend .base-title;
	padding-top: $section-padding-tiny;
}

.memorial-dialog-text{	
	@extend .base-text;	
	padding-top: $section-padding-tiny;
	width: 85%;
	margin-left: 7.5%;
	font-size: 1.0em;
}

.memorial-dialog-included{
	@extend .base-text;	
	padding-top: $section-padding-tiny;
	padding-bottom: $section-padding-xsmall;
	width: 70%;
	margin-left: 15%;
	font-size: 1.0em;
}

// .memorial-dialog-included:before{
// 	content: "includes ";
// }

.memorial-addon-gallery{
	width:100%;
	margin-left: 25px;
	margin-top: -95px;
}

.memorial-addon-wrapper{
    margin: 5px;
    float: left;
    width: 175px;
    margin-bottom: 25px;
}

.memorial-addon-border-left{
	border-left: thin 1px $brand-primary;
}

.memorial-addon-desc {
    padding: 15px;
    text-align: center;
}

.base-memorial-addon{	
	height: auto;
	font-family: $font-primary;
	color: $brand-primary;
	font-size: 1.15em;
	border: none;
}

.memorial-addon-crosses{
	@extend .base-memorial-addon;	

}

.memorial-addon-seperator{
	border-right: 1px solid $brand-primary;
	border-left: 1px solid $brand-primary;	
}

.memorial-addon-bases{
	@extend .base-memorial-addon;
}

.memorial-addon-vases{
	@extend .base-memorial-addon;
}

.memorial-dialog.ui-dialog{	
	background: $white;
	color: $brand-primary;
	padding:0px;
}

.memorial-dialog.ui-dialog .ui-dialog-content{
	padding:0;	
	overflow: hidden;
}

.memorial-dialog.ui-dialog .ui-dialog-content{
	padding:0;	
}

.memorial-mobile-dialog.ui-dialog .ui-dialog-title{
	background: $white;
}

.memorial-mobile-dialog.ui-dialog .ui-dialog-title{
	background: $brand-secondary;
	color: $white;
	font-family: $font-secondary;
	text-align: center;
	padding-top: 5px;
	font-size: 1.0em;
}

.memorial-dialog.ui-dialog .ui-dialog-titlebar{
	padding: 0;
	height: 35px;
}

.memorial-mobile-dialog.ui-dialog .ui-dialog-titlebar{
	padding: 0;
	height: 37px;
	background: $brand-secondary;
}

.memorial-dialog.ui-dialog .ui-dialog-titlebar-close, 
.memorial-mobile-dialog.ui-dialog .ui-dialog-titlebar-close {
	border:none;
	background: url(../images/dialog_close.png) no-repeat;
	right: 0;
	height: 35px;
	width:35px;
	top: 10px;
}

.memorial-dialog.ui-dialog .ui-dialog-buttonpane button{
	margin-top: 20px;
	margin-bottom: 20px;
	border:none;
}

.memorial-dialog.ui-dialog .ui-dialog-buttonpane .ui-dialog-buttonset {
    text-align: center;
    float: none !important;
} 

.memorial-dialog .ui-widget-header {
	background: $white;
	color: $brand-secondary;	
	font-weight: 300;
	border: none;
}

.memorial-dialog.ui-widget.ui-widget-content{
	border: none;	
}

.memorial-dialog .ui-widget-content{
	background: $white;
	border: none;	
}

.memorial-dialog .ui-widget-content a{
	color: $white;
}

.ui-widget-overlay
{
  opacity: .60 !important; /* Make sure to change both of these, as IE only sees the second one */
  filter: Alpha(Opacity=60) !important;
  background-color: rgb(67, 67, 67) !important; /* This will make it darker */
}

.memorial-dialog-image-container{
  overflow: hidden;
  width: 100%;
  height: 25%;
}

.memorial-dialog-image-container img{
	width:100%;
}

// ########################### GOOGLE MAPS CONTACT PAGE #############################

.acf-map {
	width: 100%;
	height: 200px;

	@media (min-width: 768px) 
  	and (max-width: 1024px) {
		height: 550px;
	}

	@media (min-width: 1024px) {
		height: 650px;
	}
}

/* fixes potential theme css conflict */
.acf-map img {
   max-width: inherit !important;
}

// ########################### CONTACT FROM 7 OVERRIDES #############################
.memorial-dialog .wpcf7 {
	font-family: $font-secondary;
	font-size: 0.75em;
	margin-left: 7.5%;	
}

.memorial-mobile-dialog .wpcf7{
	font-family: $font-secondary;
	font-size: 0.75em;
	margin-left: 7.5%;	
	width: 90%;
}

.memorial-dialog .wpcf7-response-output,
.memorial-mobile-dialog .wpcf7-response-output{
	font-family: $font-primary;
	color: $brand-secondary;
	font-size: 1.5em;
	font-weight: 400;
	text-align: center;	
	margin-left: -7.5%;
}

.memorial-dialog .wpcf7-submit, 
.memorial-mobile-dialog .wpcf7-submit {
	background-color: $brand-primary;
	font-family: $font-secondary;
	color: $white;
	width: 120px;	
	border: none;
	text-align: center;
	margin-left: 35%;

	&:hover{
		background-color: $brand-quaternary;
	}
}

.memorial-mobile-dialog .wpcf7-submit {
	margin-left: 25%;
}

.memorial-dialog .wpcf7-mail-sent-ok, .memorial-dialog .wpcf7-validation-errors{
	border: none;
	padding: 0;
	margin: 0;
}

.contact-form .wpcf7{
	font-family: $font-secondary;
	font-size: 1em;
	text-align: center;
	line-height: 3;
}

.contact-form .wpf7-recaptcha-custom{
    margin-left: 20%;
    margin-bottom: 15px;		
	@media (min-width: 768px) 
	  	and (max-width: 1024px) {
		margin-left: 20%;
		margin-bottom: 15px;	
	}

	@media (min-width: 1024px) 
		and (max-width: 1600px)  { 
		margin-left: 20%;
		margin-bottom: 15px;	
	}

	@media (min-width: 1600px)  { 
		margin-left: 20%;
		margin-bottom: 15px;	
	}	
}

.contact-form .wpcf7-submit{
	background-color: $brand-secondary;
	font-family: $font-secondary;
	color: $white;
	width: 120px;	
	height: 40px;
	border: none;
	text-align: center;

	&:hover{
		background-color: $brand-quaternary;
	}
}

// ########################### CONTACT FROM 7 OVERRIDES #############################

.contact-us-base{
	color: $black;
	font-family: $font-secondary;
	font-size: 1em;
	text-align: center;

	@media (min-width: 768px) 
	  	and (max-width: 1024px) {
	  	font-size: 1.25em;
	}

	@media (min-width: 1024px) 
		and (max-width: 1600px)  { 
		font-size: 1.5em;
	}

	@media (min-width: 1600px)  { 
		font-size: 1.75em;
	}		
}

.contact-us-base:before{
	color: $brand-secondary;
	text-transform: uppercase;
}

.contact-us-wrapper{
	text-align:center;
}

.contact-us-landline{
	@extend .contact-us-base;	
}

.contact-us-email{
	@extend .contact-us-base;	
}

.contact-us-landline, .contact-us-email{
	display:inline;
}

.contact-us-email a {
	color: $black;
	text-decoration: none;
}

.contact-us-address{
	@extend .contact-us-base;		
}

.contact-us-email:before { content: 'E:\00a0'; }
.contact-us-landline:before { content: 'P:\00a0'; }
.contact-us-mobile:before { content: 'M:\00a0'; }

// ########################### ARTICLES LANDING #############################

.article-wrapper{
	> a {
		text-decoration: none;
	}
}

.article-thumbnail{
	padding-left: 5px;
	padding-right: 5px;
}

.article-blurb-wrapper{	
	height: 140px;
	background: $white;
	padding-left: 0px;
	padding-right: 0px;
	padding-top: 15px;
	padding-bottom: 30px;

	@media (min-width: 768px) 
	  and (max-width: 1024px) {
	  	height: 180px;
	}	

	@media (min-width: 1024px) {
	  	height: 200px;
	}		

}

.article-blurb-text{
	@extend .memorial-text;
	padding-bottom: $section-padding-small;
}

.article-date{
	font-size: 1.0em;
	font-weight: 400;
	color: $brand-secondary;
	font-family: $font-primary;
	letter-spacing: 3;
	padding-top:20px;
}

.article-title{
	font-family: $font-secondary;
	font-size: 1.15em;
	color: $brand-primary;

	@media (min-width: 768px) 
	  and (max-width: 1024px) {
	  	font-size: 1.25em;
	}	

	@media (min-width: 1024px) {
	  	font-size: 1.5em;
	}		

	a {
		color: $brand-primary;
	}

	a:hover {
		color: $brand-primary;
		text-decoration: underline;
	}	
}

// ########################### ARTICLE PAGE #############################

.history-paragraph-title{
	@extend .base-title;
}

.history-paragraph-sub-title{
	@extend .base-sub-title;
	text-align: initial;
}

.history-quote{
	@extend .base-title;
	font-style: italic;
}

.history-quote:before{
	content: '"';
}

.history-quote:after{
	content: '"';
}

.history-paragraph-text{
	@extend .base-text;
	text-align: initial;
}

.history-image-gallery{
	width:100%;
	height:400px;
}

.history-image-wrapper{
    margin: 5px;
    float: left;
    width: 100%;
}

// ########################### ARTICLE PAGE #############################

.article-paragraph-title{
	@extend .base-title;
	text-align: center;
}

.article-quote{
	@extend .history-quote;
	text-align: left;
	padding-top: $section-padding-small;
}