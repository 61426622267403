// memorial gallery lightbox for bootstrap overrides (http://ashleydw.github.io/lightbox/)

.modal-content{
	border-radius: 0px;
}

.modal-body{
	padding: 0px;
}

.modal-header{
	padding: 0px;
}

.modal-title {
    margin: 0;
    line-height: 1.0;
    padding-top: 10px;
    padding-left: 10px;
    font-family: $font-primary;
    color: $brand-secondary;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: $brand-primary;
    border: 0;
}

.close {
    float: right;
    font-size: 30px;
    font-weight: 300;
    height:40px;
    width:40px;
    line-height: 1;
    color: $white;
    opacity: 1;
    font-family: $font-primary;
}

.ekko-lightbox-nav-overlay{
	padding: 10px;
}

.ekko-lightbox-nav-overlay a{
	text-decoration: none;
}

.ekko-lightbox-nav-overlay .glyphicon-chevron-right{
	float: right;
}