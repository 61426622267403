//  http://seegatesite.com/bootstrap/bootstrap-navbar-online-generator.php#

$dropDown     : true;

.navbar{
  z-index: 100000;
  margin-bottom: 0px;
  border-radius: 0px;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
    background: $brand-primary;

    @media (min-width: 768px) {
      background: transparent;
    }
}

// header navbar
.navbar-default {
  background-color: $opaque-background;
  border-color: transparent;
  .navbar-brand {
    padding-left:25px;
    padding-top:20px; 
    //
    @media (min-width: 768px) 
      and (max-width: 1024px) {
      padding-left:75px;         
    } 

    @media (min-width: 1024px) 
      and (max-width: 1600px)  { 
      padding-left:100px;
    }

    @media (min-width: 1600px)  { 
      padding-left:125px;
    }      
    //        
    &:hover, &:focus { 
      color: $brand-secondary; }}
  .navbar-text {
    color: $white; }
  .navbar-nav {
    @media (min-width: 768px) 
      and (max-width: 1024px) {
      padding-bottom:20px;
      padding-right:10px;
      margin-top: 10px;           
    } 

    @media (min-width: 1024px) 
      and (max-width: 1600px)  { 
      padding-bottom:20px;
      padding-right:20px;
      margin-top: 10px; 
    }

    @media (min-width: 1600px)  { 
      padding-bottom:20px;
      padding-right:100px;
      margin-top: 10px; 
    }      

    > li {      
      > a {
        color: $white;
        font-size: 1.0em;
        font-family: $font-primary;
        font-weight:200; 
        text-transform: uppercase;       
        background-color: transparent;   
          padding-left: 35px;
          padding-right: 35px;       
        //
        @media (min-width: 768px) 
          and (max-width: 1024px) {
          font-size: 0.85em;
          padding-left: 15px;
          padding-right: 15px;     
          padding-top:25px;          
        } 

        @media (min-width: 1024px) 
          and (max-width: 1600px)  { 
          font-size: 1.15em; 
          padding-left: 25px;
          padding-right: 25px;              
          padding-top:25px;
        }

        @media (min-width: 1600px)  { 
          font-size: 1.25em;        
          padding-top:25px;
        }     
        //   
        &:hover,  &:focus {
          color: $brand-quaternary; }}
      @if $dropDown {
        > .dropdown-menu {
            background-color: $brand-primary;
            width: 175px;  
            padding: 15px; 
            //
             @media (min-width: 768px) {
                margin-left: -25px;
                margin-top: 15px;
                background-color: $opaque-background;
             }
             //   
            > li {
                
              > a {
                color: $white;
                background-color: $brand-primary;
                font-size: 1.0em;
                font-family: $font-secondary;         
                white-space: initial;
                font-weight: 400;  
                text-align: center;  
                padding: 20px 15px 10px 15px;
                //
                 @media (min-width: 768px) {
                    background-color: transparent;
                 }              
                //
                &:hover,  &:focus, &:active {
                  color: $brand-quaternary;
                  background-color: transparent; }                                 
              }
            }
            > li:not(:last-child) {
                border-bottom: 1px solid $white;
            }
          }
        }
    }
    // 
    > .active {
      > a, > a:hover, > a:focus {
        color: $brand-quaternary;
        background-color: transparent; }}
    > .open {
      > a, > a:hover, > a:focus {
        color: $brand-quaternary;
        background-color: transparent; }}}
  //        
  .navbar-toggle {
    z-index:1000;
    border-color: $brand-primary;
    margin-top: 0;
    &:hover, &:focus {
      background-color: transparent; }
    .icon-bar {
      background-color: $white; }}   
  .navbar-collapse{
    // background-color: $brand-primary; 
  }
  .navbar-link {
    color: $white;
    &:hover {
      color: $brand-secondary; }}}
 @media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu {
    > li > a {
      color: $brand-primary;
      background-color: $white;
      &:hover, &:focus {
        color: $brand-secondary; }}
    > .active {
      > a, > a:hover, > a:focus {
        color: $brand-secondary;
        background-color: $white; }}}
 }

//  place the dropdown menu
@media (min-width: 767px) {
  .navbar-right .dropdown-menu {
    right: auto;
    left: 0;
    top: 35px;
 }
//  hide the caret
  .navbar-nav .caret {
    display:none;
 }  
}  

// force child menu to dropdown on hover
.dropdown:hover .dropdown-menu {
  display: block;
} 

