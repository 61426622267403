@import 'https://fonts.googleapis.com/css?family=Droid+Serif:400,700|Open+Sans:300,400,600,700,800';

// ########################################## Base Controls ########################################

.base-title{
	padding-top: $section-padding-standard;
	color: $brand-secondary;
	font-family: $font-primary;
	font-size: 2.0em;
	font-weight: 400;
	text-align: center;
}

.base-sub-title{
	color: $brand-secondary;
	font-family: $font-primary;
	font-size: 1.5em;
	font-weight: 400;
	text-align: center;
}

.base-text{
	color: $black;
	font-family: $font-secondary;
	font-size: 1.15em;
	text-align: center;
}

.base-link{
	font-family: $font-primary;
	font-size: 1.0em;
	font-weight: 400;
	text-align: center;
	cursor: pointer;		

	@media (min-width: 768px) 
	  and (max-width: 1024px) {
      font-size: 1.10em;
	} 

	@media (min-width: 1024px) 
	  and (max-width: 1600px)  { 
      font-size: 1.25em;
	}

	@media (min-width: 1600px)  { 
      border-left:none;
      font-size: 1.5em;
	}	
}

.base-link a {
	color:$brand-primary;
	text-decoration: none;
}

.base-link a:hover, a:focus {
    color: $brand-quaternary;
    text-decoration: primary;
}

.parent-section{
	display: table;
}

.child-section{
 	display: table-cell;
    vertical-align: middle;
    overflow: auto;
}

.diamond-border{
	padding-top: $section-padding-xsmall;
	padding-bottom: $section-padding-xsmall; 
}

// ########################################## Dividers / Seperators ########################################

// vertical dividers
.section-seperator{
	margin-bottom: 30px;
}
// vertical dividers
.section-seperator-standard{
	margin-bottom: 65px;
}

.section-seperator-large{
	margin-bottom: 75px;
}

.section-seperator-xlarge{
	margin-bottom: 95px;
}

.section-seperator-small{
	margin-bottom: 10px;
}

.section-seperator-medium{
	margin-bottom: 20px;
}

.section-paragraph{
	margin-bottom: 55px;
}

.padding-standard{
	padding-top: 15px;
	padding-bottom: 15px;
}

.section-seperator-border{
	border-top: 1px solid $brand-primary;
	width: 80%;
	margin-left: 10%;
}

// ######################################################### 
.criteria-no-match-title{
	@extend .base-title;
	padding-top: 30px;
}

.criteria-no-match-text{
	@extend .base-text;
}
