// Grid system
.main {
  @include make-sm-column($main-sm-columns);
  .sidebar-primary & {
    @include make-sm-column($main-sm-columns - $sidebar-sm-columns);
  }
}
.sidebar {
  @include make-sm-column($sidebar-sm-columns);
}

// centered columns styles
.row-centred {
    text-align:center;
}
.col-centred {
    display:inline-block;
    float:none;
    /* reset the text-align */
    text-align:left;
    /* inline-block space fix */
    margin-right:-4px;
}

// zero gutter width
.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

.col-gutter-15 {
  padding-top:15px;
  padding-bottom:15px;
}

.row-gallery {
   margin-left:-20px;
   margin-right:-20px;
}

.container-fluid-no-gutter{
   margin-left:auto;
   margin-right:auto;
}

.container-fluid-no-padding{
  padding-left: 0;
  padding-right: 0;
}

// Row with equal height columns
.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display:         flex;
}

.column-no-gutter{
    padding-right:0px;
    padding-left:0px;    
}

