// Glyphicons font path
$icon-font-path:        "../fonts/";

// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #0e6c2c;
$brand-secondary:       #004e26;
$brand-tertiary:        #f0f8f4;
$brand-quaternary:      #facb4f;
$white:					#ffffff;
$black:					#000000;
$navbar-opaque-background:  rgba(14, 108, 44, 0.1);
$opaque-background:  rgba(14, 108, 44, 0.7);

// fonts
$font-primary: 'Droid Serif', serif;
$font-secondary: 'Open Sans', sans-serif;

//
$section-padding-xsmall: 25px;
$section-padding-small: 40px;
$section-padding-medium: 55px;
$section-padding-standard: 65px;
$section-align-left-pad: 75px;
$section-padding-tiny: 10px;